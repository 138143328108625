import React from 'react';

import { StaticQuery, graphql } from 'gatsby';

import Post from './Post';

const Posts = ({ posts }) => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(
          sort: { fields: [frontmatter___date], order: DESC }
          filter: { frontmatter: { draft: { ne: true } } }
        ) {
          edges {
            node {
              ...PostFragment
            }
          }
        }
      }
    `}
    render={({ allMarkdownRemark: { edges: posts } }) => (
      <section>
        {posts.map(({ node }) => (
          <Post key={node.fields.slug} node={node} />
        ))}
      </section>
    )}
  />
);

export default Posts;
