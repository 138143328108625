import 'prism-themes/themes/prism-ghcolors.css';

import React from 'react';

import Layout from '../components/layouts';
import Posts from '../containers/Posts';
import SiteTitle from '../containers/SiteTitle';

const BlogIndex = ({ location }) => (
  <Layout location={location}>
    <SiteTitle />
    <Posts />
  </Layout>
);

export default BlogIndex;
